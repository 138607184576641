<template>
    <div v-if="!supplier">No supplier</div>
    <div v-else
         :class="['flex justify-between items-center mb-6 border rounded-md px-6 py-2', 'border-' + supplierStatus.color + '-200']">
        <div class="flex flex-col md:flex-row">
            <span>Current status:</span>
            <div :class="'text-left ml-0 md:ml-2 text-' + supplierStatus.color +'-500 truncate font-semibold uppercase tracking-wider'">{{ supplierStatus.label }}</div>
        </div>
        <div>
            <autralis-status-buttons :classNameConfig="classNameConfig"
                                     :states="states"
                                     :saving="saving"
                                     :error="error"
                                     @on-change="changeStatus"/>
        </div>
    </div>
</template>

<script>
import {STATES} from "@/master/views/Home/states-and-triggers";
import {filter, find, map, uniq} from "lodash";
import StatusButtons from "@/master/components/StatusButtons";
import {handleChangeSupplierStatus} from "@/master/views/Home/suppliers/handlers";

export default {
    name: "SupplierStatus",
    props: {
        supplierId: {
            type: Number
        },
        classNameConfig: {
            type: Object,
            default: () => {
            }
        }
    },

    data() {
        return {
            saving: false,
            error: null
        }
    },

    computed: {
        suppliers() {
            return this.$store.getters['master/suppliers'];
        },
        supplier() {
            const supplier = find(this.suppliers, s => s.id === this.$props.supplierId);
            return supplier ? supplier : null
        },
        supplierStatus() {
            return STATES[this.supplier.state] ? STATES[this.supplier.state] : null;
        },
        states() {
            return uniq(map(filter(this.suppliers, supplier => this.$props.supplierId === supplier.id), supplier => supplier.state))
        }
    },

    methods: {
        async changeStatus(trigger) {
            if (this.supplier) {
                try {
                    this.saving = true;
                    this.error = null

                    const payload = {
                        supplierID: this.supplier.id,
                        trigger: trigger
                    };
                    await handleChangeSupplierStatus(payload, (updatedSuppliers) => {
                        this.$store.commit('master/setSuppliers', updatedSuppliers)
                        this.$emit('on-success', updatedSuppliers)
                    }, (error) => {
                        this.error = error
                    })

                    this.saving = false;

                } catch (err) {
                    this.error = 'The supplier status could not be changed. Please try again.'
                    this.saving = false;
                }
            }
        },
    },

    components: {
        'autralis-status-buttons': StatusButtons
    }
}
</script>

<style scoped>

</style>