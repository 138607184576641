import axios from "axios";
import store from '../../../../store';
import {filter, find, orderBy} from "lodash";
import {generateNewState} from "../states-and-triggers";

export const handleFetchSuppliersV2 = async (payload, onSuccess, onError) => {

    const response = await axios.get(
        '/manager/expert3/xp3/suppliers-v2/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleFetchSupplier = async (payload, onSuccess, onError) => {
    const {supplier_id} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/supplier/' + supplier_id + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading supplier failed. Please try again.')
    }
}

export const handleChangeSupplierStatus = async (payload, onSuccess, onError) => {
    const {supplierID, trigger} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/change-supplier-state/',
        {
            supplierID,
            trigger
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        const otherSuppliers = filter(store.getters['master/suppliers'], s => s.id !== supplierID);

        const selectedSupplier = find(store.getters['master/suppliers'], s => s.id === supplierID);
        const updatedSupplier = {...selectedSupplier, state: generateNewState(selectedSupplier.state, trigger)}

        const updatedSuppliers = [...otherSuppliers, updatedSupplier];
        onSuccess(orderBy(updatedSuppliers, ['name'], ['asc']))
    } else {
        onError(response.data.message)
    }
}

export const handleSaveSupplier = async (payload, onSuccess, onError) => {
    const {supplier} = payload;

    const response = await axios.post(
        '/manager/expert3/xp3/save-supplier/',
        {supplier},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        const suppliers = store.getters['master/suppliers'];
        // Check if the id exists in suppliers in store
        const existing = find(suppliers, s => s.id === response.data.supplier.id)
        // If yes than update it
        // If no than add it
        let updatedSuppliers;
        if (existing) {
            updatedSuppliers = [...filter(suppliers, s => s.id !== response.data.supplier.id), response.data.supplier]
        } else {
            updatedSuppliers = [...suppliers, response.data.supplier]
        }

        // Return updated suppliers
        onSuccess({...response.data, updatedSuppliers})
    } else {
        onError(response.data.message)
    }
}